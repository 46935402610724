import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import DashboardLayout from './Layout/DashboardLayout';
import Account from './Content/Account';
import Member from './Content/Member';
import Company from './Content/Company';
import FCManage from './Content/FCManage';
import Login from './Content/Login';
import { createTheme, ThemeProvider } from '@mui/material';
import { green1 } from './const/colors';
import Notice from './Content/Notice';
import Vice from './Content/Vice';
const router = createBrowserRouter(

  [
  //첫페이지->로그인
  { 
    element: <Login/>, path: "/" },
  // 첫페이지->회사소개페이지
  { 
    element: <DashboardLayout/>,  children:[
   
      {
       
   path: "/account",
   element: <Account/>
 },
 {
       
  path: "/member",
  element: <Member/>
},
{
       
  path: "/company",
  element: <Company/>
},
{
       
  path: "/fc",
  element: <FCManage/>
},
{
       
  path: "/notice",
  element: <Notice/>
},
{
       
  path: "/vice",
  element: <Vice/>
}

] },
 

],

);
const theme = createTheme({
  palette: {
    primary: {
      main: green1, // 원하는 primary 색상
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <RouterProvider   router={router} />
  </ThemeProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
