
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DefaultTxtfield from '../Txtfield/DefaultTxtfield';
import DefaultSelect from '../Select/DefaultSelect';
import AddressForm from './AddressForm';
import { db, storage } from '../../Firebase/Firebase'; // Firebase Firestore와 Storage 가져오기
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage 모듈 추가
import './CreateMemberDialog.css';
import DefaultAutoComplete from '../AutoComplete/DefaultAutoComplete';
import LoadingDialog from './LoadingDialog';
import AudioUpload from '../Txtfield/AudioUpload'; // 녹음 파일 업로드 컴포넌트 추가
import { monthCostOptions, receiptOptions, statusOptions } from '../../const/value';
import { v4 as uuidv4 } from 'uuid'; // UUID v4 가져오기
import DateOfBirthPicker from '../Select/DateOfBirthPicker';
import DefaultTxtArea from '../TxtArea/DefaultTxtArea';

function EditMemberDialog({ open, onMemberClose ,row}) {
    const [companyOptions, setCompanyOptions] = useState([]); // 대리점 리스트 저장
    const [fcOptions, setFcOptions] = useState([]); // FC 리스트 저장
    const [selectedCompany, setSelectedCompany] = useState(null); // 선택된 대리점 저장
    const [selectedFc, setSelectedFc] = useState(null); // 선택된 FC 저장
 
//////
const [loading, setLoading] = useState(false);
const [selected, setSelected] = useState("1");
const [receiptSelected, setReceiptSelected] = useState("1");
const [statusSelected, setStatusSelected] = useState("1");
const [monthSelected, setMonthSelected] = useState("1");
const [address, setAddress] = useState({ sido: "", sigungu: "", eupmyeon: "" });
const [audioFile, setAudioFile] = useState(null); // 오디오 파일 상태 추가
const [audioSrc, setAudioSrc] = useState(null);
const [selectedEmployee, setSelectedEmployee] = useState(""); // 선택한 값을 저장할 상태
const [modalIsOpen, setModalIsOpen] = useState(false);
const [company, setCompany] = useState("");
const [fc, setFc] = useState("");
const [member, setMember] = useState("");
const [phone, setPhone] = useState("");
const [phone1, setPhone1] = useState("");
const [phone2, setPhone2] = useState("");
const [num, setNum] = useState("");
const [idOptions, setIdOptions] = useState([]); // Firestore에서 가져온 옵션을 저장
const [idLoading, setIdLoading] = useState(false);
const audioId = uuidv4();
const [year, setYear] = useState('');
const [month, setMonth] = useState('');
const [day, setDay] = useState('');
const [memo,setMemo]=useState("")
const [docId,setDocId]=useState("")
useEffect(() => {
    if (row) {
        setDocId(row.docId)
        setSelectedCompany({ id: row.companyId, label: row.company });
        setSelectedFc({ id: row.fcId, label: row.fc });
        setMember(row.member);
        const phoneParts = row.phone.split('-');
        setPhone1(phoneParts[1]);
        setPhone2(phoneParts[2]);
        const [birthYear, birthMonth, birthDay] = row.num.split('-');
        setYear(birthYear);
        setMonth(birthMonth);
        setDay(birthDay);
        setSelected(row.gender);
        setMonthSelected(row.monthCost);
        setReceiptSelected(row.receipt);
        setStatusSelected(row.status);
        setAddress(row.address);
        setMemo(row.memo);
        setAudioSrc(row.audioSrc);
    }
}, [row]);
  // 고객 삭제 함수
  const onDeleteMember = async () => {
    const confirmation = window.confirm("정말로 이 고객을 삭제하시겠습니까?");
    if (!confirmation) return;

    try {
        setLoading(true);
        const memberDocRef = doc(db, "member", docId);
        await deleteDoc(memberDocRef);
        setLoading(false);
        alert("고객이 성공적으로 삭제되었습니다.");
        onMemberClose(); // 삭제 후 다이얼로그 닫기
    } catch (error) {
        console.error("고객 삭제 중 오류 발생: ", error);
        setLoading(false);
        alert("고객 삭제 중 오류가 발생했습니다.");
    }
};

    useEffect(() => {
        // Firestore에서 대리점과 FC 리스트를 가져오는 함수
        const fetchOptions = async () => {
            try {
                setLoading(true);
                
                // 대리점 리스트 가져오기
                const companySnapshot = await getDocs(collection(db, 'company'));
                const companyList = companySnapshot.docs.map(doc => ({
                    id: doc.id,
                    label: doc.data().companyName
                }));
                setCompanyOptions(companyList);

                // FC 리스트 가져오기
                const fcSnapshot = await getDocs(collection(db, 'fc'));
                const fcList = fcSnapshot.docs.map(doc => ({
                    id: doc.id,
                    label: doc.data().fcName
                }));
                setFcOptions(fcList);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching options: ", error);
                setLoading(false);
            }
        };

        fetchOptions();
    }, []);

    const handleCompanyChange = (event, newValue) => {
        setSelectedCompany(newValue);
    };

    const handleFcChange = (event, newValue) => {
        setSelectedFc(newValue);
    };

    const handleYearChange = (event) => setYear(event.target.value);
    const handleMonthChange = (event) => setMonth(event.target.value);
    const handleDayChange = (event) => setDay(event.target.value);
    const handleAudioFileChange = (file) => setAudioFile(file);

    const onEditMember = async () => {
        try {
            setLoading(true);
    
            const empty = !selectedCompany || !selectedFc || member.trim() === "" || year === "" || month === "" || day === "" ||
                phone1.trim() === "" || phone2.trim() === "" || address.sido.trim() === "" || address.sigungu.trim() === "";
    
            if (empty) {
                alert("빈칸을 모두 채워주세요!");
                setLoading(false);
                return;
            }
    
            // If a new audio file is uploaded, handle audio file upload
            let audioDownloadURL = audioSrc; // Keep the existing audio file URL if no new file is uploaded
            if (audioFile) {
                const audioRef = ref(storage, `audios/${audioId}/${audioFile.name}`);
                await uploadBytes(audioRef, audioFile);
                audioDownloadURL = await getDownloadURL(audioRef); // Retrieve the download URL for the uploaded file
                setAudioSrc(audioDownloadURL); // Update the local state with the new audio URL
            }
    
            // Update the member data in Firestore
            const memberDocRef = doc(db, "member", row.docId); // row.docId should be passed when opening the dialog for editing
            await updateDoc(memberDocRef, {
                companyId: selectedCompany.id??"",
                fcId: selectedFc.id??"",
                company: selectedCompany.label??"",
                fc: selectedFc.label??"",
                member:member??"",
                phone: `010-${phone1}-${phone2}`??"",
                num: `${year}-${month}-${day}`??"",
                gender: selected??"",
                monthCost: monthSelected??"",
                receipt: receiptSelected??"",
                status: statusSelected??"",
                address: {
                    sido: address.sido??"",
                    sigungu: address.sigungu??"",
                    eupmyeon: address.eupmyeon??"",
                },
                audioSrc: audioDownloadURL??"", // Store the new or existing audio URL
                employee: selectedEmployee??"",
                memo:memo??"",
                updatedAt: new Date(), // Track when the member was last updated
            });
    
            setLoading(false);
            alert("고객 수정 완료!");
            onMemberClose(); // Close the dialog after editing
        } catch (e) {
            console.error("고객 수정 중 오류 발생: ", e);
            setLoading(false);
            alert("고객 수정 중 오류 발생");
        }
    };
    
    const options = [
        { value: "1", label: "여자" },
        { value: "2", label: "남자" },
    ];
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleComplete = (data) => {
        const fullAddress = data.address;
        const addressArray = fullAddress.split(" ");
        setAddress({
            sido: addressArray[0],
            sigungu: addressArray[1],
            eupmyeon: addressArray[2] || "",
        });
        setModalIsOpen(false);
    };
    const handleUploadClick = () => {
        document.getElementById('audio-upload').click();
      };
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('audio/')) {
          setAudioFile(file);
          const fileURL = URL.createObjectURL(file);
          setAudioSrc(fileURL);
        } else {
          alert('오디오 파일만 업로드할 수 있습니다.');
        }
      };
    return (
        <Dialog maxWidth="lg" 
            sx={{
                "& .MuiDialog-paper": {
                    width: "1600px", // 원하는 너비 지정
                    maxWidth: "none", // 최대 너비 제한 해제
                },
            }}
            open={open} onClose={onMemberClose}>
            <LoadingDialog open={loading} />
            <DialogTitle>고객 생성</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <div className='dialog-col'>
                    {/* 대리점 Autocomplete */}
                    <DefaultAutoComplete 
                        placeholder='대리점을 선택하세요' 
                        idOptions={companyOptions} 
                        selected={selectedCompany} 
                        handleOptionChange={handleCompanyChange}
                    />
                    {/* FC Autocomplete */}
                    <DefaultAutoComplete 
                        placeholder='FC를 선택하세요' 
                        idOptions={fcOptions} 
                        selected={selectedFc} 
                        handleOptionChange={handleFcChange}
                    />
                    <DefaultTxtfield width={"100%"} placeholder='고객명' value={member}  onChange={e => setMember(e.target.value)} />
                    <div className='phone-row'>
                        <DefaultTxtfield disabled value={"010"} width={"100%"} placeholder='연락처' />
                        <span>-</span>
                        <DefaultTxtfield width={"100%"} placeholder='0000' value={phone1} onChange={e => setPhone1(e.target.value)} />
                        <span>-</span>
                        <DefaultTxtfield width={"100%"} placeholder='0000' value={phone2} onChange={e => setPhone2(e.target.value)} />
                    </div>
                    <Typography variant='h7'>주민번호</Typography>
                    <DateOfBirthPicker year={year} month={month} day={day} handleYearChange={handleYearChange} handleMonthChange={handleMonthChange} handleDayChange={handleDayChange} />
                </div>
                <div className="divider" />
                <div className='dialog-col'>
                    <DefaultSelect width={"100%"} label={"성별"} options={options} value={selected} onChange={e => setSelected(e.target.value)} />
                    <DefaultSelect width={"100%"} label={"월보험료"} options={monthCostOptions} value={monthSelected} onChange={e => setMonthSelected(e.target.value)} />
                    <DefaultSelect width={"100%"} label={"접수유형"} options={receiptOptions} value={receiptSelected} onChange={e => setReceiptSelected(e.target.value)} />
                    <DefaultSelect width={"100%"} label={"진행상태"} options={statusOptions} value={statusSelected} onChange={e => setStatusSelected(e.target.value)} />
                    <DefaultTxtArea value={memo} onChange={e => setMemo(e.target.value)} />
                </div>
                <div className="divider" />
                <div className="dialog-col">
                <AudioUpload onFileChange={handleAudioFileChange} handleFileChange={handleFileChange} handleUploadClick={handleUploadClick} audioFile={audioFile} audioSrc={audioSrc} />
                </div>
                <div className="divider" />
                <div className="dialog-col">
                    <AddressForm handleComplete={handleComplete} isOpen={modalIsOpen} onClose={closeModal} onOpen={openModal} />
                    <DefaultTxtfield disabled placeholder='시/도' value={address.sido} />
                    <DefaultTxtfield disabled placeholder='시/군/구' value={address.sigungu} />
                    <DefaultTxtfield disabled placeholder='읍/면/동' value={address.eupmyeon} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onMemberClose}>닫기</Button>
                <Button variant='contained' onClick={onEditMember}>수정</Button>
                <Button variant='contained' color='error' onClick={onDeleteMember}>삭제</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditMemberDialog;
